.mypage-order-header {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $borderColor;
}

.order-header {
  &-title {
    font-weight: 500;
    margin-bottom: 8px;
    span {
      font-size: 14px;
      color: #999;
    }
  }
  &-left {
    padding: 8px;
  }
  &-left-wrap {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &-left-content {
    display: flex;
    flex-direction: column;
  }
  &-left-content-title {
    font-weight: 500;
    font-size: 16px;
  }
  &-left-content-text {
    font-size: 14px;
    display: flex;
    gap: 8px;
  }
  &-left-image {
    width: 120px;
    height: 80px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $borderColor;
  }
}

.order-header-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  max-width: 120px;
  width: 100%;
  padding: 8px;
  a {
    text-align: center;
  }
}
