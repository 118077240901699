.ceo-home {
  .orderChoiceMsg {
    color: #1657bf;
    font-size: 22px;
    font-weight: 500;
    margin: 80px 80px 100px 0px;
  }
  .noOrdersMessages {
    display: flex;
    justify-content: center;
    color: $white;
  }
  display: flex;
  .left {
    display: flex;
    flex-direction: column;
    background-color: #1e293b;
    width: 250px;
    height: 100vh;

    h1 {
      display: flex;
      justify-content: center;
      padding: 30px 50px 30px 50px;
      color: #fff;
    }
    .waiting-orders {
      background-color: #334155;

      .one-order {
        display: flex;
        justify-content: space-between;
        color: #f8fafc;
        padding: 30px;
      }
      .one-order.selected {
        background-color: #23497e;
      }

      .one-order-left {
      }
      .one-order-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .ceo-home-tab {
    font-size: 2em;
    color: #333;
    padding: 40px 80px;
  }

  .order-body {
    gap: 20px;
    padding: 5vw 5vw 0px 1vw;

    .orderedList {
      .oneOrder {
        .AnOrderHead {
          background-color: $black;
          color: $white;
          padding: $padding-2;
        }
        .AnOrderBody {
          display: flex;

          .AnOrderLeft {
            .requested {
              padding: $padding-2;
              margin: 8px 8px 8px 0px;
              border: 1px solid #ccc;
              width: 30vw;

              .requestedabout {
                display: flex;
                padding-top: 20px;

                padding-bottom: 50px;
                .requestedto {
                  padding-right: 50px;
                }
              }
            }
            .orderedMenu {
              border: 1px solid #ccc;
              padding: $padding-2;
              margin: 8px 8px 0px 0px;
              .orderedMenuInfandOption {
                padding-bottom: 10px;
                margin-bottom: 10px;
                .menuOptions {
                  .menuOption {
                    display: flex;
                    gap: 3vw;
                    justify-content: space-between;
                    .optionName {
                      display: flex;
                    }
                  }
                }
                .orderedMenuInf {
                  display: flex;
                  gap: 3vw;
                  justify-content: space-between;

                  .menuName {
                    width: 100px;
                  }
                  .menuAmount {
                  }
                  .menuPrice {
                    padding-left: 10px;
                  }
                }
              }

              .allOrderedMenuInf {
                display: flex;
                justify-content: space-between;
                border-top: 2px solid #ccc;
                .title {
                  width: 100px;
                }
                .allMenuPrice {
                  padding-left: 10px;
                }
              }
            }
          }
          .AnOrderRight {
            .AnOrderInf {
              padding: $padding-2;
              border: 1px solid #ccc;
              margin: 8px 0px 0px 0px;
              display: block;
            }
            .AnOrderInf > div {
              margin-bottom: 16px;
            }

            .AnOrderInf > div:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

// 주문내역과 배달주소 컬럼 높이 통일
.AnOrderBody {
  display: flex;
}

.AnOrderLeft,
.AnOrderRight {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.orderedMenu {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AnOrderInf {
  flex: 1;
  display: flex;
  p {
    padding-bottom: 30px;
  }
}
.acceptOrRefuse {
  display: flex;
  justify-content: space-between !important;
  padding-top: 10px;
  .acceptBtn {
    width: 30vw;
  }
}

.forSpace {
  width: 20vw;
}
