// 프로젝트 섹션 스타일링

.project-page-cover {
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 200%;
}

.project-section__description {
  line-height: 250% !important;
  padding: 20px;
}

.project-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;

  &__title {
    font-size: 24px;
    padding: 12px 0;
    border-bottom: 2px solid #00c4bd;
  }

  &__subtitle {
    font-size: 20px;
    padding: 8px 0;
  }

  &__description,
  &__text {
    line-height: 180%;
    padding: 20px 4px;
  }

  &__list {
    margin: 10px 0;
    padding-left: 20px;
    list-style-type: disc;

    &__item {
      // margin-bottom: 10px;
    }
  }

  &__strong {
    font-weight: bold;
  }

  &__highlight {
    font-style: italic;
  }

  &__link {
    color: #00c4bd;
    text-decoration: none;
    font-weight: 700;
    padding-right: 4px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__improvement {
    display: block;
    // margin-bottom: 5px;
  }
}

// 역할 분담
.project-roles {
  &__details {
    margin: 10px 0;
    background-color: #e9ecef;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;

    &__summary {
      font-weight: bold;
      padding: 5px;
      border-radius: 5px;
      background-color: #007bff;
      color: #fff;
      cursor: pointer;
      outline: none;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
  &__list {
    padding: 20px;
    padding-left: 20px;
    list-style-type: circle;
  }

  &__item {
    // margin-bottom: 5px;
  }

  &__sub-list {
    padding-left: 20px;
    list-style-type: square;

    &__sub-item {
      // margin-bottom: 5px;
    }
  }

  &__text {
    // padding: 20px;
    // margin-top: 15px;
  }
}

// 모바일 대응
@media (max-width: 768px) {
  .project-section {
    padding: 15px;
    margin: 10px;

    &__title {
      font-size: 20px;
    }

    &__subtitle {
      font-size: 18px;
    }
  }
}
