.reportDetail-wrap {
  padding: 120px;
  h1 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .report {
    display: flex;
    justify-content: space-between;
    .reportWriter {
      display: flex;
    }
    .reportTime {
      display: flex;
    }
  }
  .reportWhyContent {
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
    min-height: 100px;
  }

  .reported {
    display: flex;
    justify-content: space-between;
    .reportedWriter {
      display: flex;
    }
    .reportedTime {
      display: flex;
    }
  }
  .reportedWhyContent {
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
    min-height: 100px;
  }

  .buttonforreport {
    display: flex;
    justify-content: space-between;
  }

  .reportDetail-modalInput {
    border: 1px solid #ccc;
  }
}
