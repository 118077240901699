.restaurant-detail-page {
  &__info {
    border: 1px solid $borderColor;
  }
  &__info-name {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .iconforinfo {
      display: flex;
      align-items: center;
      border: 1px solid $borderColor;
      background-color: white;
      border-radius: 4px;
    }
  }
  &__info-content {
    display: flex;
    padding: 8px;
    gap: 8px;
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
  }
  &__info-image {
    width: 80px;
    height: 80px;
    border: 1px solid $borderColor;
    img {
      width: 78px;
      height: 78px;
      object-fit: cover;
    }
  }
  &__info-details {
    padding: 4px 0;
    p,
    span {
      font-size: 12px;
    }
  }
  &__info-notice {
    font-size: 12px;
    padding: 8px;
    span {
      font-weight: 700;
    }
  }
}

.restaurant-detail-page__info-rating {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  span {
    color: orange;
  }
}
