.acceptDetail-wrap {
  padding: 120px;

  h1 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .apiandSignInf {
    display: flex;
    .apiInf {
      margin-right: 40px;
    }
  }
}
