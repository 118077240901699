.restaurant-detail-page {
  &__menu {
    border: 1px solid $borderColor;
    background-color: #f8f9fd;
  }
  &__menu-header {
    display: flex;
    align-items: center;
  }
}

.header {
  &__item {
    display: flex;
    justify-content: center;
    flex: 1;
    padding: $padding-3 0;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    &:last-child {
      border-right: 0;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      padding: 0 4px;
    }
  }
}

.header__item.active {
  font-weight: 500;
  border-bottom: 4px solid #009fa8;
}

.header__item {
  font-weight: 500;
  border-bottom: 4px solid #f8f9fd;
}
