// Colors
$color-primary-100: #c6f7d3;
$color-primary-200: #90f0b3;
$color-primary-300: #54d48f;
$color-primary-400: #2aa971;
$color-primary-500: #00704a;
$color-primary-600: #006049;
$color-primary-700: #005045;
$color-primary-800: #00403e;
$color-primary-900: #003235;

$color-success-100: #e7f9cd;
$color-success-200: #caf39d;
$color-success-300: #9ddc67;
$color-success-400: #6fba3e;
$color-success-500: #398c10;
$color-success-600: #28780b;
$color-success-700: #1a6408;
$color-success-800: #0f5105;
$color-success-900: #074303;

$color-info-100: #cfe7fb;
$color-info-200: #a0ccf8;
$color-info-300: #6ea8eb;
$color-info-400: #4885d8;
$color-info-500: #1657bf;
$color-info-600: #1043a4;
$color-info-700: #0b3189;
$color-info-800: #07226e;
$color-info-900: #04185b;

$color-warning-100: #fcf2ca;
$color-warning-200: #fae396;
$color-warning-300: #f1ca60;
$color-warning-400: #e3b038;
$color-warning-500: #d18b00;
$color-warning-600: #b37100;
$color-warning-700: #965900;
$color-warning-800: #794400;
$color-warning-900: #643400;

$color-danger-100: #fcd2d9;
$color-danger-200: #f9a6bc;
$color-danger-300: #ee77a3;
$color-danger-400: #de5394;
$color-danger-500: #c92280;
$color-danger-600: #ac187a;
$color-danger-700: #901172;
$color-danger-800: #740a66;
$color-danger-900: #60065d;

$gray-50: #f8fafc;
$gray-100: #f1f5f9;
$gray-200: #e2e8f0;
$gray-300: #cbd5e1;
$gray-400: #94a3b8;
$gray-500: #64748b;
$gray-600: #475569;
$gray-700: #334155;
$gray-800: #1e293b;
$gray-900: #0f172a;

$red-light: #f8d7da;
$red-dark: #842029;
$green-light: #d1e7dd;
$green-dark: #0f5132;
