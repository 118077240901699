$height: 44px;

.location-search {
  @include flex-column-center;

  background: linear-gradient(
    to bottom,
    rgba(0, 196, 189, 1),
    rgba(161, 217, 206, 1)
  );

  padding: $padding-8 0;

  h2 {
    font-size: 2.25rem;
    color: $white;
    text-align: center;
  }

  p {
    text-align: center;
    color: $white;
    padding: $padding-2 0 $padding-5;
  }

  .location-search__group {
    @include flex-center;
    gap: $gap-2;
    width: 100%;
    height: $height;
    padding: 0 $padding-5;
  }

  .location-search__icon {
    @include flex-center;
    border-radius: $border-radius-1;
    flex-shrink: 0;
    width: $height;
    height: 100%;
    background-color: #fff;
  }

  .location-search__input {
    display: flex;
    justify-content: space-between;
    border-radius: $border-radius-1;
    overflow: hidden;
    max-width: 600px;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  .location-search__btn {
    display: block;
    padding: $padding-2 $padding-3;
    height: 100%;
    color: $white;
    background-color: $black;
    font-size: 14px;
  }
}

.location-search {
  // 랩박스
  .css-1oz3td-MuiAutocomplete-root {
    width: 100% !important;
  }

  .MuiOutlinedInput-root {
    height: $height;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0 !important;
  }
}
