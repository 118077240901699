.menu-category {
  &__item.disabled {
    position: relative;
    pointer-events: none;
    opacity: 0.6;
  }
  &__status {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    z-index: 2;
    text-align: center;
    width: 80%;
    color: black;
    opacity: 1;
  }
  &__item.disabled .__text {
    position: relative;
    z-index: 1;
  }
  .toggle-category {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: $gray;
    cursor: pointer;
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__title {
    // border-bottom: 1px solid $borderColor;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  &__item {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderColor;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  &__name {
    font-weight: 500;
  }

  &__description {
    color: #999;
    font-size: 14px;
  }

  &__price {
    font-size: 14px;
    font-weight: 700;
    color: #333;
  }

  &__image {
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}
