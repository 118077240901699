html {
  overflow-x: hidden; // 반응형 작업하기
}

.root-wrap {
  min-width: 320px;
}

.inner {
  max-width: $laptop;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 $padding-5;
}

// 컨텐츠 조절용
.none {
  display: none !important;
}

// 접근성용
.hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

.border {
  border: 1px solid $borderColor;
}

// 컨테이너 박스 테두리, 라디우스
.border-combo {
  border-radius: $border-radius-2;
  border: 1px solid $borderColor;
}

// 컨테이너 박스 테두리, 그림자, 라디우스
.border-set {
  border-radius: $border-radius-2;
  border: 1px solid $borderColor;
  box-shadow: $shadow-3;
}

.main {
  // min-height: 50vh;
  margin: $padding-4 0 $padding-13;
  // margin: 150px 0 $padding-13;
  // box-shadow: inset 0 0 10px orange; // 작업영역 확인용
}

.bold {
  font-weight: 500;
}

.bold2 {
  font-weight: 700;
}

.gray {
  color: #999;
}

.flex-center {
  @include flex-center;
}

.result__zero {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  height: 50vh;
  width: 100%;
}
