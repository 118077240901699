.order-list-gap {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.order-list {
  display: block;
  padding: 16px 16px;
  border: 1px solid #dbdbdb;
  width: 100%;
  border-radius: 12px;
}

.order-date {
  display: flex;
  color: #333;
  font-size: 12px;
  justify-content: space-between;
}

.order-logo {
  width: 60px;
  min-width: 60px;
  height: 60px;
  background-repeat: no-repeat; /* 이 속성은 이미지를 반복하지 않도록 합니다 */
  background-position: center;
  background-size: contain;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
}

.order-main {
  display: flex;
  gap: 32px;
  align-items: center;

  & div {
    font-weight: 700;
  }
}
