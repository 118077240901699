.askDetail-wrap {
  padding: 120px;
  h1 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .ask {
    display: flex;
    justify-content: space-between;
    .askWriter {
      display: flex;
    }
    .askTime {
      display: flex;
    }
  }
  .content {
    .askContent {
      border: 1px solid black;
      padding: 10px;
      margin-bottom: 10px;
      min-height: 100px;
    }
    .answerContent {
      border: 1px solid black;
      margin-bottom: 10px;

      padding: 10px;
    }
  }
  .askWriter-tab {
    padding-right: 20px;
  }
}
