.filters__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.filters__list-item.all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.restaurants-page {
  $gap: $gap-6;
  &__filters {
    position: relative;
    display: flex;
    align-items: center;
    padding: $padding-2 0;
    img {
      border-radius: 50%;
      width: 64px;
      height: 64px;
    }
    // height: 50px;

    .search-btn {
      position: relative;
      padding: $padding-1;
      border: 1px solid $borderColor;
      border-radius: $border-radius-1;
      @include flex-center;
    }
    .filters__list {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    .filters__item {
      position: relative;
      display: flex;
      cursor: pointer;
      &::before {
        position: absolute;
        content: "|";
        left: -($gap/2 + 1);
        color: $borderColor;
      }
      &:hover {
        font-weight: 500;
        color: $mainColor;
      }
    }
  }
}

.background-color {
  background-color: #fff;
  border-bottom: 1px solid $borderColor;
  box-shadow: $shadow-2;
  z-index: -1;
}

// 메뉴검색 버튼 css
.modal-menuSearch {
  position: absolute;
  display: flex;
  align-items: center;
  top: 2.875rem;
  left: 1rem;
  width: 18.75rem;
  z-index: 9;
  border: 1px solid $borderColor;
  padding: $padding-1;
  background: #fff;
  font-size: 14px;
  .wrap {
    width: 100%;
    display: flex;
  }
  input {
    background-color: #fff;
    border: 1px solid $borderColor;
    border-right: 0;
    width: 100%;
    padding: $padding-1 $padding-2;
    font-size: 12px;
  }
  &__btn {
    @include flex-center;
    padding: $padding-2;
    background-color: #999;
    color: #fff;
  }
}

.mobile-filters {
  display: none;
}

@include tablet {
  .restaurants-page {
    &__filters {
      display: none;
    }
  }

  .mobile-filters {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .toggle-filters-btn {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    background-color: #f1f1f1;
    border: none;
    width: 100%;
    text-align: center;
  }

  .filters__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .filters__item {
    width: 50%;
    padding: 8px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 0 !important;
  }
}
