// 프로젝트 전반에서 사용되는 변수를 정의합니다.

/** 

버튼 크기는 24px * 24px 이상

*/

// 기본 색상
$black: #333;
$white: #fff;
$borderColor: #dbdbdb;
$gray: #e6e6e6;
$textGray: #999;
$backgroundColor: #f8fafc;
$red: red;

$mainColor: #00c4bd;

// 두께
$regular: 400;
$medium: 500;
$bold: 700;

// border-radius
$border-radius-1: 4px;
$border-radius-2: 8px;
$border-radius-3: 12px;
$border-radius-4: 16px;

// gap
$gap-1: 4px;
$gap-2: 8px;
$gap-3: 12px;
$gap-4: 16px;
$gap-5: 20px;
$gap-6: 24px;
$gap-7: 28px;
$gap-8: 32px;
$gap-9: 36px;
$gap-10: 40px;

// padding
$padding-1: 4px;
$padding-2: 8px;
$padding-3: 12px;
$padding-4: 16px;
$padding-5: 20px;
$padding-6: 24px;
$padding-7: 28px;
$padding-8: 32px;
$padding-9: 36px;
$padding-10: 40px;
$padding-11: 44px;
$padding-12: 48px;
$padding-13: 52px;

// letterSpacing
$letterSpacing: -0.3px;

// transition
$transition: 0.3s ease-in-out all;

$max-width: 1120px;
$fixed-width: 600px;
$fluid-width: 90vw;

// 그림자
$shadow-1:
  0 1px 3px 0 rgba(0, 0, 0, 0.1),
  0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-2:
  0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-3:
  0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-4:
  0 20px 25px -5px rgba(0, 0, 0, 0.1),
  0 10px 10px -5px rgba(0, 0, 0, 0.04);

// 반응형
$desktop: 1400px;
$laptop: 1024px;
$tablet: 768px;
$mobile: 480px;

// 라벨 width
$width: 120px;
