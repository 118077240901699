.restaurant-detail-page {
  display: flex;
  gap: $gap-3;
  &__left {
    display: flex;
    flex-direction: column;
    gap: $gap-3;
    width: 65%;
  }
  &__right {
    position: sticky;
    top: 80px;
    width: 35%;
    min-height: 1vh;
    height: 100%;
  }
}

@include tablet {
  .restaurant-detail-page {
    flex-direction: column;
    &__left {
      width: 100%;
    }
    &__right {
      width: 100%;
    }
  }
}
