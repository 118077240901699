.ceo-page__menu-item {
  color: #333;
  text-decoration: none;
  padding: 10px 15px;
  display: block;

  &.active {
    color: $black;
    font-weight: bold;
    background-color: $backgroundColor;
  }

  &:hover {
    background-color: $gray;
  }
}

.ceo-page__content-wrap {
  padding: 0px 0px 110px 0px;
}

.ceo-page__content {
  padding: 0px 0px 100px 0px;
}

@media (max-width: 768px) {
  .ceo-page__menu {
    display: none;
  }
}
