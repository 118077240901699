.accept-wrap {
  padding: 120px;
  .tap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    font-weight: 900;
    * {
      width: 100px;
      display: flex;
      justify-content: center;
    }
    .tap-title {
      width: 300px;
      display: flex;
      justify-content: center;
    }
  }
  .askList {
    .oneAsk {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      height: 50px;
      align-items: center;
      * {
        width: 100px;
        display: flex;
        justify-content: center;
      }
      .tap-title {
        width: 300px;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        direction: ltr; /* 텍스트를 오른쪽에서 왼쪽으로 읽도록 설정 */
      }
    }
  }
  .tap-number {
    width: 110px !important;
  }
  .tap-status {
    width: 250px !important;
  }
  @media (max-width: 1244px) {
    .tap-title {
      width: 100px !important;
    }
  }
}
