.coupon-wrap {
  border: 1px solid #cdcc;
  .coupon-management {
    padding: 40px;
  }
}

.couponForResDetail {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
}

.oneCoupon {
  margin: 20px;
  background: #fff;
  border: 2px solid #ff5722;
  border-radius: 10px;
  padding: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.couponTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: #ff5722;
}

.couponContent {
  font-size: 1em;
  color: #333;
}

.discount {
  font-size: 1.5em;
  font-weight: bold;
  color: #4caf50;
}

.minOrder {
  font-size: 0.9em;
  color: #888;
}

.oneCoupon:before,
.oneCoupon:after {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  border: 2px dashed #ff5722;
}

.oneCoupon:before {
  top: -10px;
  left: calc(50% - 10px);
}

.oneCoupon:after {
  bottom: -10px;
  left: calc(50% - 10px);
}

//사장님 쿠폰
.coupon-wrapforstore {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 40px;
  border: 1px solid #ccc;
  .coupon-management {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }

    .btn {
      display: inline-block;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 20px;
      transition: background-color 0.3s ease;
    }

    .add-coupon-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
      }

      btn {
        align-self: flex-end;
        padding: 10px 20px;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        display: flex;
        justify-content: center;
        &:hover {
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        background-color: #f2f2f2;
      }

      th,
      td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }

      th {
        font-weight: bold;
      }

      tbody tr:hover {
        background-color: #f1f1f1;
      }
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);

      .modal-content {
        background: #ffffff;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        h2 {
          margin-bottom: 20px;
        }
      }
    }
  }
}
