.categorySetting-wrap {
  padding: 120px;
  .category-header {
    display: flex;
    margin-bottom: 40px;
    h1 {
      font-size: 24px;
      margin-bottom: 40px;
      margin-right: 100px;
    }
    .category-button {
      .btn {
        margin-right: 40px;
      }
    }
  }
}
.category-edit-buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.category-edit-buttons button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.category-edit-buttons button:hover {
  background-color: #0056b3;
}
