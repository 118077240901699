/* 쿠폰 목록 스타일 */
.coupons-page__list {
  list-style-type: none; /* 리스트 점 제거 */
  padding: 0;
  margin: 0;
}

.coupon-item {
  padding: 15px;
  background-color: #ffffff; /* 항목 배경을 흰색으로 설정 */
  border: 2px solid #00c4bd; /* 버튼과 동일한 테두리 색상 */
  border-radius: 5px; /* 버튼과 동일한 모서리 둥글기 */
  margin-bottom: 10px; /* 항목 사이에 여백 추가 */
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
  cursor: pointer;
}

.coupon-item:hover {
  background-color: #00c4bd; /* 호버 시 배경색을 오렌지로 */
  border-color: #00c4bd; /* 호버 시 테두리 색상 유지 */
  color: #ffffff; /* 호버 시 텍스트 색상을 흰색으로 */
}

/* 쿠폰 목록이 없을 때의 스타일 */
.result__zero {
  padding: 15px;
  background-color: #ffffff;
  border: 2px solid #00c4bd;
  border-radius: 5px;
  color: #00c4bd; /* 텍스트 색상을 오렌지로 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}
