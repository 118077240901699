// Sidebar.scss

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1000;
}

.sidebar-overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

.sidebar__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  img {
    width: 150px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  background: white;
  width: 250px;
  height: 100vh;
  padding: 20px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease; /* 부드러운 애니메이션 추가 */
  z-index: 1001;
  position: relative;
}

.sidebar.open {
  transform: translateX(0); /* 사이드바가 열릴 때의 상태 */
}

.sidebar__nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar__nav-item {
  margin-bottom: 20px;
}

.sidebar__nav-item a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.sidebar__nav-item a:hover {
  color: #007bff; /* 링크 호버 색상 */
}

.sidebar__nav-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.sidebar__nav-item button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;
}

.sidebar__nav-item button:hover {
  color: #0056b3; /* 버튼 호버 색상 */
}
