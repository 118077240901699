.statistics {
  padding: 40px 15vw;
  h2 {
    font-size: 30px;
    padding-bottom: 40px;
  }

  .selected-unit {
    display: flex;
    justify-content: center;
  }

  .buttonforstatistics {
    display: flex;
    justify-content: center;
  }
  .btn {
    margin: 0px 20px 0px 20px;
  }

  .statistics-title {
    font-size: 2em;
    color: #333;
    padding: 40px 80px;
  }
  .tabforchoice {
  }
}
.datepicker {
  border: 3px solid skyblue;
  border-radius: 4px;
}
.tabforsales {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .tabforsalesUl {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 10px;
  }
}

.tabforchoice {
  display: flex;
  justify-content: center;

  .tabforchoiceUl {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 10px;
  }
}

.tabforsalesUl li {
  margin: 0;
}

.btnforYearChart,
.btnforMonthChart,
.btnforDayChart {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  // background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btnforYearChart:hover,
.btnforMonthChart:hover,
.btnforDayChart:hover {
  // background-color: #0056b3;
}

@media (max-width: 768px) {
  .statistics-title {
    font-size: 2em;
    color: #333;
    padding: 40px 80px;
    display: flex;
    justify-content: center;
  }
}
