.restaurant-detail {
  display: flex;
  flex-direction: column;
  &__overall-score {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid $borderColor;
    border-top: 1px solid $borderColor;

    .overall-score {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__value {
        font-size: 42px;
        line-height: 1;
      }

      &__icon {
        color: orange;
      }
    }

    .score-items {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      gap: 4px;
      border-left: 1px solid $borderColor;
      p,
      span {
        font-size: 12px;
      }

      .score-item {
        display: flex;
        gap: 4px;
        &__title {
          width: 28px;
        }

        &__icon {
          color: orange;
        }

        &__value {
          font-weight: 500;
          color: orange;
        }
      }
    }
  }
}

// 리뷰 갯수 표시
.restaurant-detail {
  .review-list {
    &__filter {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $borderColor;

      p {
        font-size: 12px;
      }

      .filter__text {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-weight: bold;
        }
      }

      .filter__photo-reviews {
        display: flex;
      }
    }
  }
}
