.btn-base {
  padding: $padding-2 $padding-3;
  border-radius: $border-radius-1;
  font-size: 14px;
  transition: background-color 0.3s ease; /* 호버 효과에 애니메이션을 추가할 경우 */
  &:hover {
    background-color: darken($mainColor, 10%);
    color: $white;
  }
}

// 수정해야하는 코드인데 이미 너무 많이 사용하고 있음
.btn {
  @extend .btn-base;
  color: $white;
  background-color: $mainColor;
}

.btn--black {
  @extend .btn-base;
  color: $white;
  background-color: $black;
}

.btn--active {
  @extend .btn-base;
  color: $white;
  background-color: $mainColor;
}

.btn--default {
  @extend .btn-base;
  color: $black;
  background-color: $white;
  border: 1px solid $borderColor;
}

.btn--cancel {
  @extend .btn-base;
  color: $white;
  background-color: $red;
  border: 1px solid $borderColor;
}
