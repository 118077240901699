.ceo-order-wrap {
  margin: 0px 15vw;

  .ceo-order-tab {
    font-size: 2em;
    color: #333;
    padding: 40px 0px;
  }
  .ceo-order-content {
    border: 1px solid $borderColor;
    border-top: none;
    .ceo-orderList {
      .ceo-order-header {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ccc;

        .order-header-left {
          .order-header-title {
          }
          .order-header-left-wrap {
            .order-header-left-image {
            }
            .order-header-left-content {
              .order-header-left-content-title {
              }
              .order-header-left-content-text {
              }
            }
          }
        }
        .order-header-right {
          justify-content: center;
        }
      }
    }

    .noListMsg {
      display: flex;
      border-top: 1px solid $borderColor;
      justify-content: center;
      padding: 20px;
    }
  }
  .orderListing {
    .tabforchoiceUl {
      li {
        display: flex;

        .btn {
          background-color: rgb(197, 197, 197);
          color: black;
          border: 1px solid #ccc;
          border-bottom: none;

          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: 10px;
        }

        .btn.active {
          background-color: white;
          color: $mainColor;

          border-bottom: 1px solid white;
        }
      }
    }
  }
}
.paginationforOrderHistory .active {
  padding-left: 10px;
  padding-right: 10px;

  background-color: grey;
  border-radius: 4px;
  color: white;
}

.paginationforOrderHistory {
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .btnNextandBefore {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.ceo-orderList:first-child {
  border-top: none;
}
