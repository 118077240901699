$bc: #f3f3f3;

.restaurants-page {
  .filters {
    display: flex;
    gap: 8px;
  }
  .filters__select {
    font-weight: 500;
    font-size: 12px;
    padding: 10px 12px;
    background-color: #f3f3f3;
    border-radius: 30px;
    cursor: pointer;
    &:hover {
      // background-color: #d3d3d3;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 32px 16px;

    .restaurant-item {
      border-radius: $border-radius-1;

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
      &__top {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        .rank-point {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: #ddd;
          font-size: 12px;
          font-weight: 500;
        }
      }
      &__title {
        font-weight: 400;
        font-size: 16px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__rank-point {
        display: flex;
        p {
          font-size: 14px;
          color: #999;
          &::after {
            content: "|";
            padding: 6px;
            color: $borderColor;
          }
          &:last-child {
            &::after {
              content: "";
            }
          }
        }
      }
    }
  }
}

.rank-point {
  display: flex;
  align-items: center;
  gap: $gap-1;
  svg {
    font-size: 12px;
    color: #ffcc00;
  }
}

.restaurants-page__title {
  padding: $padding-2 0;
}

.img-cover {
  width: 100%;
  height: 130px;
  overflow: hidden;
}

@include tablet {
  .restaurants-page__list {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 32px 16px;
  }

  .restaurants-page__list .restaurant-item {
    max-width: 100%;
  }
}

.filters__select.active {
  background-color: black;
  color: white;
}
