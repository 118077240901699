.mypage-wrap {
  .order-list-header {
    display: flex;
    // justify-content: space-between; // 링크들을 양쪽으로 배치
    background-color: #fff; // 배경색 추가
  }

  .header-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px; // 버튼 높이 설정
    border-bottom: 2px solid transparent; // 기본 상태의 테두리
    text-align: center;
    color: #333; // 텍스트 색상
    text-decoration: none; // 링크 밑줄 제거
    font-size: 16px; // 글자 크기

    &:hover {
      border-bottom: 2px solid $mainColor; // 호버 시 테두리 색상 변경
      color: $mainColor; // 호버 시 텍스트 색상 변경
    }
  }
}
