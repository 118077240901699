.mypage-review-wrap {
  width: 100%;
  padding: 32px 0;

  .rating-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .rating-score {
      font-size: 2em;
      font-weight: bold;
    }

    .rating-stars span {
      margin-right: 12px;
    }
  }

  .review-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .review-count {
      font-weight: bold;
    }

    .review-switch label {
      display: flex;
      align-items: center;

      input {
        margin-right: 12px;
      }
    }
  }

  .reviews {
    border-top: 1px solid #dbdbdb;

    .review {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #dbdbdb;

      .review-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .review-user {
          font-weight: bold;
        }

        .review-date {
          color: #333;
        }

        .review-rating {
          color: #f39c12;
        }
      }

      .review-content-mypage {
        display: flex;
        img {
          margin-right: 20px;
          margin-bottom: 12px;
        }

        h2 {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}

.review-font {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.order-detail-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
