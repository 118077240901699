.user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 40px;
  height: 64px;
  background-color: #fff;
  font-size: 14px;
}

.user-header {
  &__menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__logo {
    width: 150px;
  }
  &__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  &__location {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 50px;
    cursor: pointer;
  }
  &__search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    background-color: #f3f3f3;
    border-radius: 50px;
    flex-grow: 1;
    .search__input {
      padding: 0 8px;
      outline: none;
      flex-grow: 1;
    }
    .search__clear-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border-radius: 50%;
      &:hover {
        background-color: #333;
        color: #fff;
      }
    }
    .search__btn {
      padding: 4px 8px;
      margin-left: 8px;
      background-color: #333;
      color: #fff;
      font-weight: 500;
      border-radius: 4px;
    }
  }

  &__auth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  &__auth.column {
    flex-direction: column;
  }
}

.auth-btn {
  padding: 8px 16px;
  border-radius: 50px;
}

.user-header__auth-login {
  &:hover {
    background-color: #f3f3f3;
  }
}

.user-header__auth-signup {
  background-color: #f3f3f3;
}
