.menu-management {
  margin-bottom: 100px;
  border: 1px solid #cdcc;
  .menu-section {
    margin-bottom: 100px;
    padding: 40px;
    .menu-category__title {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      background-color: $gray;
      cursor: pointer;
    }
    .plsAddMenues {
      display: flex;
      justify-content: center;
      margin: 100px;
    }
    .menu-tap {
      background-color: $black;
      color: $white;
      padding: $padding-2;
    }

    .menu-list {
      display: flex;
      flex-direction: column;
      border: 1px solid $borderColor;
      .menu-upper {
        display: flex;
        justify-content: center;

        .menu-add {
          display: flex;
          padding: 8px; // 내부 여백
          cursor: pointer;
        }
      }
      .menu-list-oneMenu {
        border-top: 1px solid $borderColor;
        padding: 20px;
        &:last-child {
          border-bottom: none;
          border-radius: 0px 0px 4px 4px;
        }

        .menu-list-oneMenu-table {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .picanddata {
            display: flex;
            .menu-list-oneMenu-tablePic {
              img {
                width: 120px;
                height: 80px;
              }
            }

            .menu-list-oneMenu-tableData {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 15px;
              .menu-list-price {
                font-size: 12px;
              }
              .menu-list-content {
                color: $textGray;
              }
            }

            .menu-list-oneMenu-tablePic {
              width: 100px;
            }
          }
          .status-action {
            display: flex;
            .menu-list-status {
              display: flex;
              margin: 0 auto;
              .menu-list-select {
                font-size: 14px;
                width: 70px;
                padding: $padding-1 $padding-2;
                border: 1px solid $borderColor;
              }
              .menu-list-select-soldOut {
                font-size: 14px;
                font-weight: 900;
                width: 70px;
                padding: $padding-1 $padding-2;
                border: 1px solid $borderColor;
                border-color: red;
                color: red;
              }
            }
            // .menu-list-status {
            //   display: flex;
            //   justify-content: center;
            // }
            .menu-list-actions {
              .btn {
                margin-left: 10px;
              }
              .btn--cancel {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
  .menu-settings {
    .settings-header {
      font-size: 2em;
      padding: 40px 15vw;
    }
    .menu-upper {
      padding: 0px 40px;

      .menu-add {
        display: flex;
        justify-content: center;
        .btn {
          margin: 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .settings-header {
    font-size: 2em;
    color: #333;
    padding: 40px 80px;
    display: flex;
    justify-content: center;
  }
  .menu-section {
    margin: 0px 0px 100px !important;
  }
}
