.join-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 40px;
  align-items: center;
  flex-direction: column;
  padding: 120px 0px;

  & h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #333;
    text-align: center;
    letter-spacing: 1.2px00;
  }
}

.join-top {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  font-size: 24px;
  color: white;
  text-align: center;
}

.join-button {
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  & a {
    width: 100%;
    min-width: 180px;
    max-width: 550px;
    height: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 20px 0;
    border: 2px solid #dbdbdb;
    box-shadow: $shadow-1;
    cursor: pointer;
    border-radius: 8px;
  }

  & p {
    display: flex;
    justify-content: center;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: normal;
    color: #333;
    text-align: center;
    letter-spacing: 0.8px;
  }

  & h3 {
    text-align: center;
  }
}
.join-user-img {
  width: 100%;
  height: 15vh;
  display: flex;

  background-image: url("../../../public/images/woman-8671666_640.png");
  background-repeat: no-repeat; /* 이 속성은 이미지를 반복하지 않도록 합니다 */
  background-position: center;
  background-size: contain;
}

.join-ceo-img {
  width: 100%;

  height: 15vh;
  display: flex;
  background-image: url("../../../public/images/man-8677824_640.png");
  background-repeat: no-repeat; /* 이 속성은 이미지를 반복하지 않도록 합니다 */
  background-position: center;
  background-size: contain;
}

.line {
  display: flex;
}

@include tablet {
  .join-button {
    flex-direction: column;
  }
}

/* 소셜 로그인 */
.소셜로그인 {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  gap: 48px !important;
}

.카카오로그인 {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #fee500;
  font-size: 14px;
  border-radius: 213213123px;
  // background-color: orange;
}
.네이버로그인 {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #05d686;
  font-size: 14px;
  border-radius: 213213123px;
  // background-color: orange;
}
.구글로그인 {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #fff;
  font-size: 14px;
  border-radius: 213213123px;
  background-color: #eaeaea;
  // background-color: orange;
}

.아이콘 {
  width: 30px !important;
  height: 30px !important;
  // background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.텍스트 {
  width: 100%;
  max-width: 110px !important;
  display: flex;
  // background-color: orange;
  // justify-content: center;
  // align-items: center;
}
