.category {
  width: 100%;

  &__list {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(200px, 1fr)
    ); /* 최소 너비 200px, 가득 채우기 */
    gap: $gap-2;
  }

  &__item {
    height: 220px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    z-index: 1;
    cursor: pointer;
    border-radius: $border-radius-2;
    border: 1px solid $borderColor;
    &:hover {
      box-shadow: $shadow-3;
    }
    > p {
      position: absolute;
      font-weight: $medium;
      padding: $padding-2;
      &:hover {
        font-weight: 700;
      }
    }
    > img {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
    }
  }

  /* 첫 번째 줄 */
  &__list:first-of-type {
    justify-content: flex-start; /* 첫 번째 줄의 정렬 방식 */
  }

  /* 두 번째 줄 이후 */
  &__list:not(:first-of-type) {
    justify-content: space-between; /* 나머지 줄의 정렬 방식 */
  }
}
