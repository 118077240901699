.mypage-wrap {
  display: flex;
  gap: 20px;
}

.mypage-select {
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-top: 32px;

  & button {
    padding: 8px 8px;
    border: 1px solid #dbdbdb;
    text-align: center;
    transition:
      background-color 0.3s,
      color 0.3s;
    cursor: pointer;
    border-radius: 4px;
  }
}

.mypage-box {
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 32px 0;
}

.mypage-title {
  height: auto;
  display: flex;
  gap: 52px;
  align-items: center;
  padding: 20px 20px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.mypage-title-box {
  width: 100px;
  min-width: 100px;
  display: flex;
  justify-content: start;
  border-right: 1px solid #dbdbdb;
  text-align: center;
}

.mypage-title-box-right {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  & div {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  & .btn {
    height: min-content;
  }
}

.mypage-button-box {
  display: flex;
  gap: 16px;
  justify-content: end;
}

.mypage-address {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & button {
    width: fit-content;
    padding: 8px 12px;
    background-color: #333;
    color: white;
    border-radius: 4px;
  }
}

.modify-modal {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;

  & div {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  & h2 {
    font-size: 24px;
    font-weight: 700;
  }

  & h3 {
    font-size: 24px;
    color: #1f1f1f;
    border-bottom: 2px solid #1f1f1f;
    padding-bottom: 12px;
    width: 100%;
  }

  & h4 {
    font-size: 12px;
    color: #666;
    width: 100%;
  }
}

.button-sit {
  width: fit-content;
  padding: 8px 12px;
  background-color: #333;
  color: white;
  border-radius: 4px;
}

.mypage-img {
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  & h3 {
    display: flex;
    justify-content: center;
    font-size: 24px;
  }

  & img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: contain;
  }
}

.input-box {
  width: 100%;
  flex-direction: column;
  padding-right: 20px;

  & input {
    width: 100%;
  }
}

.mypage-button-box-flex {
  flex-direction: row !important;
}

.MuiBox-root {
  width: 100%;
  min-width: 50px;
}

.Withdrawal {
  & input {
    width: 95%;
  }
}

.img-size {
  width: 120px;
  height: 80px;
}

.review-imgs-box {
  display: flex;
}

.review-img-box {
  display: flex;
}

.null-item {
  width: 100%;
}

.order-detail-text {
  cursor: pointer;
}

.address-button-box {
  display: flex;
  gap: 12px;
  justify-content: end;

  & button {
    width: 120px;
  }
}

.select-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  display: inline-block;
}

.select-container select {
  width: 100%;
  padding: 10px 40px 10px 15px;
  border: 1px solid #ccc;
  outline: none;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
  appearance: none;
}

.select-container select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.select-container::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 12px;
  color: #333;
  pointer-events: none;
  transform: translateY(-50%);
}

.select-container option {
  padding: 10px;
  background-color: #fff;
  color: #333;
}

.select-container option:hover {
  background-color: #f1f1f1;
}

.flex-between-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flex-between-real-box {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & .btn {
    padding: 0px 16px;
  }
}

.coupon-img {
  background-image: url("../../../public/images/vecteezy_discount-coupon-icon-sign-design_9342079.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coupon-price {
  width: 100%;
  display: flex;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & .btn {
    height: 80%;
  }
}

.flex-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h3 {
    font-size: 20px;
  }

  & h2 {
    font-size: 16px;
  }
}

@include tablet {
  .mypage-wrap {
    display: block;
  }

  .mypage-select {
    flex-direction: row;
    flex-wrap: wrap;

    & button {
      width: 86px;
    }
  }

  .flex-between-box {
    display: flex;
    justify-content: center;
  }

  .address-button-box {
    justify-content: center;
  }
}
