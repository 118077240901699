.option-modal {
  background-color: #fff;
  width: 400px;
  max-width: 90%;
  margin: 10% auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
    text-align: center;
  }

  .options {
    margin-bottom: 20px;

    div {
      margin-bottom: 10px;

      label {
        display: block;
        font-size: 1rem;
        margin-bottom: 5px;
        color: #555;
      }

      select {
        width: 100%;
        padding: 8px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        color: #333;
        background-color: #f9f9f9;
        &:focus {
          border-color: #333;
          outline: none;
        }
      }
    }
  }

  .modal-actions {
    display: flex;
    justify-content: space-between;

    button {
      padding: 10px 20px;
      font-size: 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:first-child {
        background-color: #ccc;
        color: #333;
      }

      &:last-child {
        background-color: #007bff;
        color: #fff;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .option-modal {
    width: 100%;
    margin: 20% auto;
  }
}
