.mypage-order {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $black;
    color: $white;
    padding: $padding-2;
    button {
      position: absolute;
      right: 8px;
      padding: 4px 8px;
    }
  }

  &__title {
    font-weight: $medium;
  }

  &__contents {
    border-bottom: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
  }

  &__thanks {
    font-size: 1.5rem;
    font-weight: $medium;
    text-align: center;
    padding: $padding-8 0;
    margin: 0 50px;
    border-bottom: 1px solid $borderColor;
  }

  &__confirmation {
    font-weight: $medium;
    text-align: center;
    padding: $padding-7 0;
  }

  &__section-title {
    font-weight: $medium;
    background-color: $gray;
    padding: $padding-2;
    margin: 8px 0;
  }

  &__detail {
    display: flex;
    align-items: center;
    padding: $padding-3 $padding-2;
  }

  &__label {
    font-weight: $medium;
    max-width: $width;
    width: 100%;
  }

  &__value {
    font-size: 14px;
  }

  &__item {
    padding: 0 $padding-2;
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      justify-content: space-between;
      padding: $padding-3 0;
    }
  }
  &__total {
    margin: $padding-2;
    border-top: 1px solid $borderColor;
    padding: $padding-5 0 $padding-3;
    display: flex;
    justify-content: space-between;
  }
  &__total-label,
  &__total-price {
    font-size: 1rem;
    font-weight: $medium;
  }
}

.mypage-order-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@include mobile {
  .mypage-order__label {
    max-width: 6.25rem;
  }
}
