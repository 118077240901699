:root {
  --swiper-navigation-color: #fff;
  --swiper-navigation-sides-offset: -15px;
}

swiper-container::part(container) {
  position: static;
}

swiper-container::part(button-prev),
swiper-container::part(button-next) {
  width: 8px;
  height: 16px;
  padding: 8px;
  border: 1px solid rgba($color: #fff, $alpha: 0.8);
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.carousel {
  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    width: 100%;
    border-radius: $border-radius-2;
  }

  &__top {
    display: flex;
    padding: $padding-2 $padding-1;
  }

  &__title {
    font-weight: $medium;
  }

  &__rank-point {
    display: flex;
    svg {
      margin-right: 4px;
      color: #ffcc00; // Adjust color as needed
    }
  }

  &__comment-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: $padding-2;
    background-color: #fff;
    border-radius: $border-radius-2;
    border: 1px solid $borderColor;
    font-size: 14px;
    p {
      font-size: 12px;
    }
  }
}
