// Break Point

/** 

$desktop: 1400px;
$laptop: 1024px;
$tablet: 768px;
$mobile: 480px;

*/

// Mixins
@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$laptop}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}
