$primary-color: #4caf50;
$secondary-color: #f9f9f9;
$text-color: #333;
$border-radius: 5px;

.store-management {
  background-color: $secondary-color;
  border-radius: $border-radius;
  .store-management-tab {
    font-size: 2em;
    color: #333;
    padding: 40px 15vw;
  }
  .store-management-body {
    margin: 0px 15vw;
    .tabs {
      .btn {
        background-color: rgb(197, 197, 197);
        color: black;
        border: 1px solid #ccc;
        border-bottom: none;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 10px;
      }

      .btn.active {
        background-color: white;
        color: $mainColor;

        border-bottom: 1px solid white;
      }
    }

    .status-taps {
      border: -1px solid $borderColor;
      background-color: $black;
      color: $white;
      padding: 8px;
      margin: 0px 15vw;
    }
    .statusandInfo {
      border: 1px solid #ccc;
      padding: 40px;

      .status-section {
        display: flex;
        margin-bottom: 20px;
        align-items: center;

        .status-title {
          padding-right: 20px;
        }

        div {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        select {
          padding: 8px;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: $border-radius;
        }

        option {
          font-size: 16px;
        }
      }
    }
  }
}

.statusborder {
  padding: 0px 0px 100px 0px;
}
.info-section {
  h3 {
    color: #1657bf;
    border-bottom: 2px solid #1657bf;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  textarea {
    width: 100%;
    max-height: 500px;
    padding: $padding-2;
    border: 1px solid $borderColor;
    resize: none;
    font-size: 14px;
  }
  .time {
    display: flex;
    input {
      font-size: 14px;
      width: 49% !important;
      padding: $padding-1 $padding-2;
      border: 1px solid $borderColor;
    }
  }
  input {
    font-size: 14px;
    width: 100%;
    padding: $padding-1 $padding-2;
    border: 1px solid $borderColor;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .button-wrap {
    padding-top: 20px;
  }
  .btnforimg {
    background-color: $black;
    margin-right: 30px;
  }

  .btn--cancel {
    margin-left: 10px;
  }
  p {
    margin: 10px 0;

    h4 {
      display: inline-block;
      color: $text-color;
      font-weight: bold;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
