.login-main {
  padding: 100px 0 52px;
}

.social-login {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 18px;
  gap: 8px !important;

  & div {
    display: flex;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
}
.auto-user {
  flex-direction: row;
}

.social-login-kakao {
  background: #e8e8e8;
}

.social-login-naver {
  background: #e8e8e8;
}

.social-login-google {
  background: #e8e8e8;
}

.line {
  width: 100%;
  margin-top: 8px;
  border-bottom: 1px solid black;
}
