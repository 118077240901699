// ModalForHome, MenuManagement, OrderAccepted의 모달 창 공유

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.modal-options button {
  margin: 0 10px;
  padding: 10px;
  font-size: 18px;
}

.modal-options span {
  font-size: 18px;
  margin: 0 10px;
}

.modal-confirm,
.modal-close {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
