.user-join-realwrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user-join-wrap {
  width: 50%;
  // height: 500px;
  // display: flex;
  // gap: 28px;
  // align-items: end;
  // justify-content: center;
  // flex-direction: column;
  // padding: 52px 40px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  // // border: 1px solid #000;
  // background-color: #f5f5dc;
  // // background-image: url("../../../public/images/delicious-sushi-rolls-with-soy-sauce-table.jpg");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover; /* 또는 contain */
  // border-radius: 24px;

  & h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #333;
  }

  & h3 {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    color: #333;
  }
  & h6 {
    cursor: pointer;
  }

  & span {
    width: 100%;
  }

  .btn {
    width: 120px;
  }
}

.flex-colum {
  display: flex;
  flex-direction: column;
}

.login-image-box {
  width: 100%;
  min-width: 40px;
  height: 100%;
  background-image: url("../../../public/images/—Pngtree—drink\ coffee\ fruit\ juice\ table_3925056.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* 또는 contain */
}

.user-join-form {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & div {
    width: 100%;
    display: flex;
    gap: 8px;
  }

  & h3 {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: start;
  }
}

.checkbox-group {
  & label {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  & input {
    cursor: pointer;
  }
}

.image-preview {
  width: 80px !important;
  height: 80px;
  margin-top: 20px;
}

.join-button {
  width: 100%;
  display: flex;
}

.id-check {
  width: 100px !important;
  height: 60px;
  padding: 0 8px;
  background: #5e5e5e;
  color: #fff;
  border-radius: 4px;
}

.login-find {
  justify-content: space-between;

  & div {
    justify-content: end;
    gap: 4px;
  }

  & h6 {
    font-weight: 400;
  }
}

.find-Id {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 180px 50px;
  background: #00000080;
}

.find-text {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  font-size: 1.875em;
  font-weight: 600;
  color: inherit;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.login-form {
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 20px;

  & h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 900;
    color: #e5eef2;
  }

  & .btn {
    width: 100%;
    padding: 16px 0;
    border-radius: 6px;
    font-size: 16px;
  }
}

.display-block {
  display: block !important;
}

.logo-margin-box {
  margin-bottom: 40px;
}

.check-button-box {
  & .btn {
    width: 150px;
    padding: 12px 0;
  }
}

@include tablet {
  .user-join-form {
    width: 100%;
  }

  .user-join-wrap {
    width: 90%;
  }

  .login-image-box {
    display: none;
  }
}
