@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.menu-wrap {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  .left {
    width: 65%;

    &.menu-expanded {
      width: 64.2%;
    }
    .restaurant-info {
      border: 1px solid $borderColor;
      background: $backgroundColor;

      .restaurant-title {
        display: flex;
        padding: 10px 20px;
        align-items: center;
        border-bottom: 1px solid $borderColor;
      }
      .restaurant-logoandcontent {
        display: flex;
        .restaurant-logo {
          object-fit: cover;
          // border: 1px solid #d9d9d9;

          .restaurant-logo-img {
            padding: 10px;
            width: 100px;
            height: 100px;
          }
          img {
            border-radius: 8px;
          }
        }

        .restaurant-content {
          padding: 10px;

          .restaurant-list {
            .list-item {
              display: flex;
              align-items: center;
              margin-bottom: 5px;
            }

            .list-minimumOrder {
              font-size: 12px;
              color: red;
            }
          }
        }
      }
    }

    .choice {
      .threeChoices {
        display: flex;
        justify-content: center;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;

        ul {
          display: flex;
          width: 100%;
          button {
            flex: 1;
            padding: 10px;
            border-right: 1px solid $black;

            &:nth-child(1) {
              border-left: 1px solid $black;
            }
            &.active {
              background-color: #1657bf;
              color: $white;
            }
          }
        }
      }
    }

    //메뉴
    .menu-section {
      .menu-slide {
        width: 100%;
        overflow: hidden;
        .swiper-container {
          display: flex;
          margin: 0 auto;
          background-color: $backgroundColor;
          padding: 20px;
          border: 1px solid $borderColor;
          margin-bottom: 20px;

          .oneMenu {
            // margin-right: 10px;
            border: 1px solid $borderColor;

            .menuTitle {
              padding-left: 14px;
              padding-top: 8px;
            }
            .menuPrice {
              padding-left: 14px;
              padding-bottom: 8px;
            }
            p {
              font-size: 12px;
            }
            img {
              border-radius: 8px;
            }
          }
        }
      }

      .menu-component {
        .menu-tap {
          border: -1px solid $borderColor;
          background-color: $black;
          color: $white;
          padding: 12px;
        }

        .menu-list {
          display: flex;
          flex-direction: column;
          border: 1px solid $borderColor;

          .menu-list-oneMenu {
            border-bottom: 1px solid $borderColor;
            padding: 10px;
            &:last-child {
              border-bottom: none;
              border-radius: 0px 0px 4px 4px;
            }

            .menu-list-oneMenu-table {
              display: flex;
              justify-content: space-between;

              .menu-list-oneMenu-tableData {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .menu-list-price {
                  font-size: 12px;
                }
                .menu-list-content {
                  color: $textGray;
                }
              }

              .menu-list-oneMenu-tablePic {
                width: 100px;
              }
            }
          }
        }
      }
    }

    //리뷰
    .review-wrap {
      border: 1px solid $borderColor;
      width: 100%;
      padding: 20px;

      .rating-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .rating-score {
          font-size: 2em;
          font-weight: bold;
        }

        .rating-stars span {
          margin-right: 10px;
        }
      }

      .review-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .review-count {
          font-weight: bold;
        }

        .review-switch label {
          display: flex;
          align-items: center;

          input {
            margin-right: 10px;
          }
        }
      }

      .reviews {
        border-top: 1px solid $borderColor;
        padding-top: 20px;

        .review {
          border-bottom: 1px solid $borderColor;
          padding-bottom: 20px;
          margin-bottom: 20px;

          .review-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .review-headerUserandDate {
              justify-content: space-between;
            }

            .review-user {
              font-weight: bold;
            }

            .review-date {
              color: #888;
            }

            .review-rating {
              color: #f39c12;
            }
          }

          .review-content {
            img {
              width: 100%;
              margin-bottom: 10px;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }

    //정보 part
    .info-section {
      width: 100%;

      padding: 20px;
      background-color: $backgroundColor;
      border: 1px solid $borderColor;

      h3 {
        color: $black;
        margin-bottom: 10px;
        border-bottom: 1px solid $borderColor;
        padding-bottom: 5px;
      }

      p {
        font-size: 12px;
        color: $black;
        margin-bottom: 8px;

        h4 {
          font-weight: bold;
          color: $black;
          font-size: 12px;
        }
      }

      p {
        display: flex;
        justify-content: space-between;
      }

      h2 {
        margin-top: 20px;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  .right {
    display: flex;
    width: 35%;
    flex-direction: column;
    align-items: flex-start;
    &.menu-expanded {
      width: 35.8%;
    }
    .order-tab {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      background: $black;
      color: $white;
      padding-left: 12px;

      .orderList {
        font-weight: 500;
      }
    }

    .order-menu {
      font-size: 12px;
      display: flex;
      width: 100%;
      max-height: 400px;
      text-align: center;
      flex-direction: column;
      border: 1px solid $borderColor;
      background: $white;
      overflow-y: auto;

      .notOrdered {
        padding: 50px 0;
      }

      .order-menu-yes {
        padding-top: 10px;
        padding-left: 10px;
        text-align: initial;
        flex-direction: column;
        padding-bottom: 10px;
      }

      .order-menu-lower {
        display: flex;
        justify-content: space-between;
      }
    }

    .order-price-none {
      padding: 10px;
    }

    .order-price {
      display: flex;
      width: 100%;
      height: 42px;
      padding: 11px 15px;
      justify-content: flex-end;
      align-items: center;
      border: 1px solid #ddd;
      background: #fff;
    }

    .order-button {
      display: flex;
      width: 100%;
      height: 55px;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      background: #ccc;
      color: #fff;
      opacity: 0.65;
      cursor: not-allowed;

      &.disabled {
        background: #ccc;
        color: #999;
        cursor: not-allowed;
        pointer-events: none;
      }

      &:not(.disabled) {
        background-color: $black;
        opacity: 1;
        cursor: pointer;
      }

      &:hover:not(.disabled) {
        cursor: pointer;
      }
    }
  }

  @media (min-width: 760px) {
    .order-buttonforphone-border {
      display: none;
    }
  }

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: stretch;

    .review-wrap {
      width: 100%;
    }
    .info-section {
      width: 100%;
    }
    .menu-slide {
      display: none;
    }

    .left,
    .right {
      width: 100%;
    }

    .left {
      &.menu-expanded {
        width: 100%;
      }
    }

    .right {
      .order-tab,
      .order-menu,
      .order-price,
      .order-button {
        display: none;
      }

      .order-menu {
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }
}

.clear-order {
  padding: $padding-2;
  @include flex-center;
}

.order-buttonforphone-border {
  .order-buttonforphone {
    position: fixed;
    bottom: 10px;

    width: calc(100% - 40px);
    display: flex;
    height: 55px;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    color: #fff;
    opacity: 0.95;
    cursor: not-allowed;
    z-index: 9999999;

    &.disabled {
      background: #ccc;
      color: #999;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:not(.disabled) {
      background-color: $black;
      opacity: 1;
      cursor: pointer;
    }

    &:hover:not(.disabled) {
      cursor: pointer;
    }
  }
}

.list-star {
  color: #f39c12;
}
