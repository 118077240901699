.ceo-page {
  &__header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
  }

  &__controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  &__button {
    padding: 8px 12px;
    background-color: $gray-700;
    color: white;
    border-radius: 4px;
    &:hover {
      background-color: $gray-500;
    }
  }

  &__main {
    display: flex;
  }

  &__menu {
    width: 200px;
    background-color: #f8f9fa;
    border-right: 1px solid #dee2e6;
    padding: 20px 0;

    &-item {
      display: block;
      padding: 10px 20px;

      &:hover {
        background-color: #e9ecef;
      }
    }
  }

  &__content-wrap {
    flex-grow: 1;
    padding: 20px;
  }

  &__content {
    min-height: 50vh;
    border-radius: 4px;
  }
}

// 추가 CSS
.owner-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2d3539;
  color: #fff;
  height: 90px;
  &__left {
    width: 200px;
    display: flex;
    justify-content: center;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: 14px;
    margin-right: 16px;
  }

  &__user {
    font-weight: 500;
  }

  &__item {
    padding: 8px;
  }
}

.owner-header__logo {
  display: flex;
  align-items: center;
  img {
    height: 60px;
  }
}

.owner-header__item.logout {
  cursor: pointer;
}
