.intro-page__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  height: 60px;
  font-weight: 700;
  color: #000;
  &-logo {
    font-size: 24px;
  }
  &-buttons {
    display: flex;
    gap: 8px;
    font-size: 14px;
  }
  .intro-btn {
    padding: 8px 16px;
    border-radius: 32px;
  }

  &-login {
    background-color: #fff;
  }
  &-signup {
    background-color: #000;
    color: #fff;
    font-weight: 400;
  }
}

.intro-page {
  width: 100%;
  min-height: 100vh;
  background: url("../../../public/images/intro.webp") center center / cover
    no-repeat fixed;
  display: flex;
  align-items: center;
}

.intro-page__inner {
  padding: 0 40px;
}

.intro-page__main {
}

.intro-page__title {
  color: #000;
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 20px;
}

.intro-page__location-search {
  max-width: 600px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 8px;
  &-input {
    max-width: 600px;
    width: 100%;
    background-color: #fff;
  }
}

.intro-page__location-search-btn {
  display: flex;
  align-items: center;
  padding: 12px 28px;
  background-color: #000;
  color: #fff;
  height: 56px;
  border-radius: 8px;
}

.intro-page__signup {
  font-size: 14px;
  text-decoration: underline;
}
