.footer-title {
  width: 100%;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
  padding: 48px 48px;
  background-color: rgba($color: #000000, $alpha: 0);
  background-color: transparent;
}
