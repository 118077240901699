.ceo-review-wrap {
  @media (max-width: 768px) {
  }
  .review-tap {
    font-size: 2em;
    color: #333;
    padding: 40px 15vw;
  }
  .review-body {
    margin: 0px 15vw;
    .review-section {
      .filter-buttons {
        .btn {
          background-color: rgb(197, 197, 197);
          color: black;
          border: 1px solid #ccc;
          border-bottom: none;

          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: 10px;
        }

        .btn.active {
          background-color: white;
          color: $mainColor;

          border-bottom: 1px solid white;
        }
      }
    }
    .review-content {
      border: 1px solid #ccc;

      .rating-section {
        display: flex;
        justify-content: center;
        padding-top: 20px;

        .overall-score {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &__value {
            font-size: 42px;
            line-height: 1;
          }

          &__icon {
            color: orange;
          }
        }
      }

      .review-list__filter {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $borderColor;

        p {
          font-size: 12px;
        }

        .filter__text {
          display: flex;
          align-items: center;
          gap: 8px;

          span {
            font-weight: bold;
          }
        }

        .filter__photo-reviews {
          display: flex;
        }
      }

      .reviews {
        display: flex;
        justify-content: center;
        .reviews-wrap {
          img {
            width: 40%;
          }
          .review {
            display: flex;
            padding: 20px;
            .review-header {
              display: flex;
              flex-direction: column;
              padding-right: 20px;
              .review-rating {
                color: orange;
              }
            }
          }
        }
      }
    }
  }
}

.textarea-custom {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
  min-height: 100px;
  max-height: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
}

.ceo-reply {
  margin-top: 10px;

  .ceo-reply-header {
    margin-bottom: 5px;
    font-size: 12px;
    color: #888;
  }

  textarea {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .review-tap {
    font-size: 2em;
    color: #333;
    padding: 40px 80px;
    display: flex;
    justify-content: center;
  }
  .review-content {
    margin: 0px 0px 100px !important;
  }
}
